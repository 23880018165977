<template lang="pug">
div(v-if='renderComponent' class='flex items-center')
  Wrench(
    v-if='isAddToCartMode'
    class='inline-block align-middle w-[32px] h-[27px] text-action-dark fill-current'
    style='max-height: 27px'
  )

  div(class='relative' :class='{ "pl-6": isAddToCartMode }')
    div(class='flex flex-wrap items-center')
      span(class='font-bold') Add Professional Installation

      UniversalLink(
        v-if='(isAddToCartMode || isCheckoutMode) && $sitewideConfig.config.installernetPageUrl'
        :link='$sitewideConfig.config.installernetPageUrl'
        :open-new-window='true'
      )
        InfoIcon(class='w-4 h-4 cursor-pointer text-action fill-current align-middle ml-1 focus:outline-none')

    div(class='flex flex-wrap gap-x-1 text-sm')
      span(class='font-bold') RealPro
      span(v-if='isQuoteAvailable' class='whitespace-nowrap text-gray font-bold') Available in
      span(v-else class='whitespace-nowrap text-gray font-bold') Not available in
      div(class='text-dark underline cursor-pointer whitespace-nowrap' @click='changeLocation') {{ city }}, {{ state }} {{ zip }}

    div(class='flex items-center')
      BaseForm(v-if='isQuoteAvailable' class='mt-2' :name='`installForm_${item.itemId}`')
        BaseFormCheckbox(
          v-model='checked'
          :classes='{ outer: "$reset mb-0", wrapper: "$reset mb-0" }'
          :name='`includeInstallation_${item.itemId}`'
          style='margin-bottom: 0px'
        )

      div
        span(class='text-base text-gray font-bold') Include Installation:
        span(v-if='quote.sellPrice !== quote.regularPrice' class='inline-flex items-center ml-1 font-bold space-x-2')
          span(class='text-lg font-bold') {{ formatCents(quote.sellPrice) }}
          s(class='text-xs text-gray') {{ formatCents(quote.regularPrice) }}
        span(v-else class='font-bold text-lg') {{ formatCents(quote.regularPrice) }}
    div(v-if='errorText' class='text-danger text-xs')
      | {{ errorText }}

    div(v-if='isWaiting' class='bg-black opacity-50 absolute inset-0 flex justify-center items-center')
      div(class='loader !border-[7px] !w-12 !h-12')
</template>

<script setup lang="ts">
import InfoIcon from '@/assets/info.svg?component'
import Wrench from '@/integrations/installernet/assets/wrench.svg?component'

const { $geolocation, $installernet, $uiEvents } = useNuxtApp()
const fitmentStore = useFitmentStore()
const { getApiUrl } = useUrls()
const notifications = useNotifications()

const { item, mode = 'CHECKOUT' } = defineProps<{
  item: CartItem
  mode?: 'ADD_TO_CART' | 'CHECKOUT'
}>()

const renderComponent = ref(false)
const isWaiting = ref(false)
const errorText = ref('')
const quote = ref<Quote | null>(null)
const city = ref('')
const state = ref('')
const zip = ref('')

onMounted(() => {
  city.value = (isCheckoutMode.value && item.installationAvailable?.city) || $geolocation.city
  state.value = (isCheckoutMode.value && item.installationAvailable?.state) || $geolocation.region
  zip.value = (isCheckoutMode && item.installationAvailable?.zip) || $geolocation.zipcode

  if (isCheckoutMode.value && item.installation) {
    quote.value = item.installation
    renderComponent.value = true
  } else {
    getQuote(zip.value)
  }
})

onUnmounted(() => {
  // cleanup this sku
  $installernet.setQuote(item.sku)
})

const fitmentData = computed(() => {
  // Use item fitment if it exists
  if (item.fitmentData)
    return {
      year: item.fitmentData.year,
      make: item.fitmentData.makeName,
      model: item.fitmentData.modelName,
      bed: item.fitmentData.bedName && item.fitmentData.bedName,
      body: item.fitmentData.bodyName && item.fitmentData.bodyName,
      engine: item.fitmentData.engineName && item.fitmentData.engineName,
    }

  return fitmentStore.$state
})

// Our expiration threshold is 1 day sooner than the actual expiration of the quote
const installExpirationDate = computed(() => {
  if (!quote.value?.expirationDate) return
  return Date.parse(quote.value.expirationDate) - 86400000
})

const isQuoteAvailable = computed(() => {
  return !!quote.value
})

const isCheckoutMode = computed(() => {
  return mode === 'CHECKOUT'
})

const isAddToCartMode = computed(() => {
  return mode === 'ADD_TO_CART'
})

const checked = computed({
  get() {
    return isCheckoutMode.value ? !!item.installation : !!$installernet.getQuoteIdBySku(item.sku)
  },
  async set() {
    errorText.value = ''

    if (!checked.value) {
      if (isCheckoutMode.value) {
        // We need a valid quote, city, and state. Early out if not.
        if (!quote.value?.quoteId || !city.value || !state.value) {
          errorText.value = 'Unable to add, please try again.'
          return
        }

        isWaiting.value = true

        try {
          await $installernet.addInstallation(item.itemId, quote.value.quoteId, city.value, state.value)
          $uiEvents.$emit('installationAdded', item)
        } catch (error) {
          errorText.value = 'Unable to add, please try again.'
        }

        isWaiting.value = false
      } else {
        // Store the sku:quote so we can add it to cart later
        $installernet.setQuote(item.sku, quote.value?.quoteId)
      }

      return
    }

    if (isCheckoutMode.value) {
      isWaiting.value = true

      try {
        await $installernet.removeInstallation(item.itemId)
        $uiEvents.$emit('installationRemoved', item)
      } catch (error) {
        errorText.value = 'Unable to remove, please try again.'
      }

      isWaiting.value = false
    } else {
      // Remove the quote by setting the sku to nothing
      $installernet.setQuote(item.sku)
    }

    // Get a new quote if it expired
    if (!installExpirationDate.value || Date.now() >= installExpirationDate.value) {
      getQuote()
    }
  },
})

watch(
  () => $geolocation.zipcode,
  async (newZip) => {
    // If we are in isCheckoutMode and have an installationAvailable from the cartdata
    // we do not care about the global geolocation
    if (isCheckoutMode.value && item.installationAvailable) return

    // If we are in addToCart we need to clear our sku:quote array
    if (isAddToCartMode.value) $installernet.clearQuotes()

    await getQuote(newZip)

    city.value = $geolocation.city
    state.value = $geolocation.region
    zip.value = $geolocation.zipcode
  }
)

function changeLocation() {
  const options: GeolocationOptions = {}
  // If we are on isCheckoutMode and have an installationAvailable
  // We need the location modal to be specific to this instance
  // and not update the global geolocation state on save
  if (isCheckoutMode.value && item.installationAvailable) {
    // locationData comes from the caller at the time this callback is called
    options.cb = async (locationData: LocationData) => {
      try {
        isWaiting.value = true

        // Update the Quote for this item, this will also remove it and refresh the cart
        await $installernet.updateInstallation(
          item.itemId,
          locationData.city,
          locationData.region,
          locationData.zipcode
        )

        // Update local state
        city.value = locationData.city
        state.value = locationData.region
        zip.value = locationData.zipcode

        // Refresh the quote we wish to offer them for the new zip
        // getQuote will handle the this.isWaiting from here
        getQuote(locationData.zipcode)
      } catch (error) {
        notifications.addError('Error', 'Unable to update installation. Please try again later.')
        isWaiting.value = false
      }
    }

    options.city = city.value
    options.region = state.value
    options.zipcode = zip.value
  }

  $geolocation.openModal(options)
}

async function getQuote(newZip?: string) {
  try {
    isWaiting.value = true

    const url = getApiUrl('installation') + 'quotes'
    const { data } = await $fetch<QuoteResponse>(url, {
      method: 'POST',
      body: {
        zip: newZip || zip.value,
        skuSlug: item.skuSlug,
        details: {
          year: fitmentData.value.year && parseInt(fitmentData.value.year),
          make: fitmentData.value.make,
          model: fitmentData.value.model,
          ...(fitmentData.value.bed && { bed: fitmentData.value.bed }),
          ...(fitmentData.value.body && { body: fitmentData.value.body }),
          ...(fitmentData.value.engine && { engine: fitmentData.value.engine }),
        },
      },
    })

    quote.value = data
    isWaiting.value = false
    renderComponent.value = true

    // Only fire this event when NOT in checkout mode
    if (!isCheckoutMode.value) $uiEvents.$emit('installernetOffered', item)
  } catch (error) {
    quote.value = null
    isWaiting.value = false

    // ---
    // UWP-9816 - turned this feature off / we wanted to keep this here in case we
    // wanted to turn this back on easily (but right now its pointless)
    // ---
    // Validation Error: Sku good / Zipcode bad
    // if (error?.data?.code === 'NO_INSTALLERS_FOUND') {
    //   // We can render, but we will tell them its not available in they zipcode
    //   renderComponent.value = true
    //   return
    // }

    // Any other error is bad so don't render
    renderComponent.value = false
  }
}

interface Quote {
  quoteId: string
  sellPrice: number
  regularPrice: number
  expirationDate: string
}

interface QuoteResponse {
  data: Quote
}

interface LocationData {
  city: string
  region: string
  zipcode: string
}

interface GeolocationOptions {
  cb?: Function
  city?: string
  region?: string
  zipcode?: string
}
</script>
